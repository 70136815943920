<script setup>
import { useRouter } from "vue-router";
const router = useRouter();
</script>

<template>
  <div class="topbar">
    <div class="topbar-container">
      <svg
        class="logo"
        viewBox="0 0 30 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click="() => router.push(localeLocation('/'))"
      >
        <rect y="10" width="3.75" height="30" fill="black" />
        <rect y="36.25" width="30" height="3.75" fill="black" />
        <rect x="7.5" y="10" width="3.75" height="22.5" fill="black" />
        <rect x="15" y="10" width="3.75" height="15" fill="black" />
        <rect x="22.5" y="10" width="3.75" height="7.5" fill="black" />
        <rect x="22.5" y="13.75" width="7.5" height="3.75" fill="black" />
        <rect x="7.5" y="28.75" width="22.5" height="3.75" fill="black" />
        <rect x="15" y="21.25" width="15" height="3.75" fill="black" />
      </svg>

      <div class="top-actions">
        <div class="topbar-language">
          <language-select />
        </div>
        <div class="topbar-account">
          <AccountNav />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.topbar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  background: white;
  box-shadow: 0px 1px 0px 0px rgba(230, 230, 230, 1);
}
.topbar-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 960px;
  max-width: 100%;
  margin: 0 auto;

  @media screen and (max-width: 1000px) {
    padding: 0 20px;
  }
}
.logo {
  cursor: pointer;
  width: 30px;
  height: 50px;
  transition: 160ms ease-in-out all;

  &:hover {
    transform: scale(0.8);
  }
}
.topbar-account {
  cursor: pointer;
}
.top-actions {
  display: flex;
  gap: 16px;
  align-items: center;
}
</style>
