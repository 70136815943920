<script setup>
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useI18n } from "vue-i18n";

import { useNotification } from "@kyvg/vue3-notification";

const { data, isFetched } = useCurrentUserSubscriptionQuery();
const { notify } = useNotification();

watch(
  () => isFetched.value,
  () => {
    console.log(isFetched.value, data.value);
    if (isFetched.value) {
      if (data.value && !data.value.howDidYouFindUs) {
        isOpen.value = true;
      }
    }
  },
);

import { useQueryClient } from "@tanstack/vue-query";

const queryClient = useQueryClient();

const { mutate: sendAnswer } = useHowDidYouFindUsMutation();

const isOpen = ref(false);

const { $intercom } = useNuxtApp();
const { gtag } = useGtag();
const i18n = useI18n();

const submit = () => {
  const values = {
    howDidYouFindUs: selected.value,
  };

  if (!values.howDidYouFindUs) {
    values.howDidYouFindUs = "skipped";
  } else if (values.howDidYouFindUs === "Other") {
    values.howDidYouFindUsText = otherText.value;
  }

  sendAnswer(values, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["user"],
      });
    },
  });

  if (values.howDidYouFindUs !== "skipped") {
    $intercom.trackEvent("Onboard feedback provided");
    gtag("event", "Onboard feedback provided", values);
    notify({ type: "success", text: i18n.t("how-did-you-find-us.success") });
  }

  $intercom.trackEvent("Onboard feedback completed");
  gtag("event", "Onboard feedback completed");

  submitted.value = true;
  isOpen.value = false;
};

const emit = defineEmits();

const options = [
  { key: "Search engine", tag: "how-did-you-find-us.search" },
  { key: "Social media", tag: "how-did-you-find-us.social" },
  { key: "Email campaign", tag: "how-did-you-find-us.email" },
  { key: "Another website", tag: "how-did-you-find-us.another-website" },
  { key: "Referral", tag: "how-did-you-find-us.referral" },
  { key: "Event", tag: "how-did-you-find-us.event" },
  { key: "Blog article", tag: "how-did-you-find-us.blog" },
  { key: "Other", tag: "how-did-you-find-us.other" },
];

const selected = ref("");
const otherText = ref("");
const submitted = ref(false);

const selectKey = (key) => {
  selected.value = key;

  if (key !== "Other") {
    submit();
  }
};

const showTextarea = computed(() => selected.value === "Other");

watch(
  () => isOpen.value,
  () => {
    if (isOpen.value) {
      setTimeout(() => {
        document.activeElement.blur();
      }, 10);
    }
    if (!isOpen.value && !submitted.value) {
      submit();
    }
  },
);

watch(
  () => showTextarea.value,
  () => {
    if (showTextarea.value) {
      setTimeout(() => {
        const element = document.getElementById("other-textarea");
        console.log(element);
        element?.focus();
      }, 200);
    }
  },
);
</script>

<template>
  <Dialog v-model:open="isOpen">
    <DialogContent class="max-w-[600px]">
      <DialogHeader>
        <DialogTitle class="max-w-[80%]"
          >{{ $t("how-did-you-find-us.welcome") }},
          {{ data.firstName }} 👋</DialogTitle
        >
      </DialogHeader>
      <p class="mb-4">{{ $t("how-did-you-find-us.description") }}</p>

      <div>
        <p class="mb-3">
          <strong>{{ $t("how-did-you-find-us.question") }}</strong>
        </p>

        <div class="flex flex-col gap-2">
          <Button
            v-for="option in options"
            :key="option.key"
            :variant="
              selected === option.key ? 'secondary' : 'secondary-outlined'
            "
            :squared="true"
            @click="() => selectKey(option.key)"
            size="lg"
            >{{ $t(option.tag) }}</Button
          >
          <Collapsible v-model:open="showTextarea">
            <CollapsibleContent class="m-[-4px] p-[4px]">
              <div class="flex flex-col gap-8">
                <Textarea
                  id="other-textarea"
                  :placeholder="$t('how-did-you-find-us.textarea-placeholder')"
                  v-model="otherText"
                /><Button @click="submit" size="lg">{{
                  $t("how-did-you-find-us.finish")
                }}</Button>
              </div></CollapsibleContent
            ></Collapsible
          >
        </div>
      </div>
    </DialogContent>
  </Dialog>
</template>
